/***********************************************************************************************************************
 ****************************************** Entry point ****************************************************************
 **********************************************************************************************************************/

// Create the player that will be embedded inside myWebApp
const urlSearchParams = new URLSearchParams(window.location.search);
const position = urlSearchParams.get('position');
const rotation = urlSearchParams.get('rotation');
const player = MetarealPlayerSDK.create('myWebApp');
const tourURL = `https://tour.metareal.com/apps/player?asset=4219dabd-6042-4019-81f5-4e90640491d6` + (position ? `&position=${position}&rotation=${rotation}` : '' );
console.log(tourURL);
const appSheetBaseURL = 'https://www.appsheet.com/start/44698102-65dc-4e96-828c-4ccc317f7c7b#appName=PMPRMIpourVV-2141529';
let currentLabel;

// Load the tour
player.load(tourURL, () => {
  console.log('Tour is loaded');

  // Start the player automatically
  player.startShowcase();
});

player.setEventListener('ready', () => {
  player.hide( 'FULLSCREEN' );
});

// Setup the player and event listener
player.setEventListener('label2clicked', labelClicked);
player.setEventListener('roomviewpointchanged', hidePanel);
player.setEventListener('switchview', hidePanel);
player.setEventListener('startmove', hidePanel);

//Button event listener
document.getElementById('close-btn').addEventListener('click', hidePanel);


/***********************************************************************************************************************
 ****************************************** Function declaration *******************************************************
 **********************************************************************************************************************/

/**
 * Event listener callback for the label2opened event
 * @param label the label object
 */
function labelClicked(label) {
  if (label !== currentLabel) {
    currentLabel = label;
    refreshPanel(currentLabel).then(show => {
      if (show) showPanel();
    });
  } else {
    togglePanel();
  }
}

/**
 * Refresh the panel content
 * @param label the label object
 * @return {Promise<boolean>}
 */
async function refreshPanel(label) {
  const entityId = label.link;
  if (!entityId) {
    hidePanel();
    alert('Le label n\'est associé à aucune entité.');
    return false;
  } else {
    const panelContent = document.getElementById('myPanelContent');
    panelContent.setAttribute('src', appSheetBaseURL + '&page=detail&row=' + entityId + '&sort=%5B%5D&table=%C3%89quipements&view=Inventaire');
    return true;
  }
}

/**
 * Display the side panel
 */
function showPanel() {
  const infoPanel = document.getElementById('myInfoPanel');
  infoPanel.classList.add('show');
}

/**
 * Return true if the panel is visible, false otherwise
 * @return {boolean}
 */
function isPanelVisible() {
  const infoPanel = document.getElementById('myInfoPanel');
  return infoPanel.className.includes('show');
}

/**
 * Change the panel state : close it if open and vice versa
 */
function togglePanel() {
  if (isPanelVisible()) {
    hidePanel();
  } else {
    showPanel();
  }
}

/**
 * Hide the side panel
 */
function hidePanel() {
  currentLabel = undefined;
  const infoPanel = document.getElementById('myInfoPanel');
  infoPanel.classList.remove('show');
}
